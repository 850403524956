/** 
 *   新品首发
 */
import { get } from '@/untils/js/axios.js'

// 新品
export const getNewGoodsList = params => get('/goods/columns-goods/2', params)

// 新品类别
export const getNewTypeList = params => get('/goods/columns-topping-type', params)

// 新品类别下的商品
export const getTypeGoodsList = (id,params) => get(`/goods/columns-type-goods/${id}`, params)

// 新品分类
export const getClassifyList = params => get('/goods/columns-type/2', params)
